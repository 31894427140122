import flatpickr from "flatpickr"
import rangePlugin from "flatpickr/dist/plugins/rangePlugin"
import "flatpickr/dist/l10n/nl.js"
import "flatpickr/dist/l10n/de.js"
import {dispatch} from "alpinejs/src/utils/dispatch";

// ? These components function with alpine + tailwind UI components

window.obaia.Components = {}

window.obaia.Components.briqWidget = (options) => {
    // <div className="bw-widget" data-default-visitors="[['volwassene', 9], ['kinderen', 0]]" data-user-culture="nl-NL" data-domain="othmar" data-use-sentry="false" data-use-ga="false" data-color-1-base="#BE4617" data-color-1-contrast="#fff" data-color-2-base="#191919" data-color-2-contrast="#fff" data-initial-state="bw=d3N8aXN8RmlsdGVyc3xhbmR8b3xpc3x0cnVlfGFuZHxmfGlzfGN8ZmlzfGFycmFuZ2VtZW50ZW58Y2lzfGJpZXJzcGEtZW4tYnJvdXdlcmlqdG91cixiaWVyc3BhLWJyb3V3ZXJpanRvdXItZW4tYmllcnByb2V2ZXJpaixicm91d2VyaWp0b3VyLWVuLWJpZXJwcm9ldmVyaWosYmllcnNwYS1lbi1iaWVycHJvZXZlcmlqfGZhbmR8"></div>
    // <button class='bw-widget-toggle' data-widget-state='bwr=ws|is|Filters|and|o|is|true|and|f|is|c|fis|arrangementen|cis|brouwerijtour-en-bierproeverij,bierspa-en-brouwerijtour,bierspa-brouwerijtour-en-bierproeverij,bierspa-en-bierproeverij|cand|activiteiten|cis|brouwerijtour,bierspa,bierproeverij|fand|'>Tickets</button>
    return {
        personen: '',
        datum: Date.now(),
        activiteiten: options.activiteiten ?? '',
        arrangementen: options.arrangementen ?? '',
        init() {
            // Activiteiten
            // brouwerijtour,bierspa,bierproeverij
            // arrangementen
            // brouwerijtour-en-bierproeverij, bierspa-en-brouwerijtour, bierspa-brouwerijtour-en-bierproeverij, bierspa-en-bierproeverij
            this.datum = this.formatDate(this.datum)
            let widgetState = this.buildWidgetState()
            this.updateWidgetAttribute(widgetState)
        },
        formatDate(date) {
            if (!(date instanceof Date)) {
                date = new Date(date)
            }
            let day = String(date.getDate()).padStart(2, '0')
            let month = String(date.getMonth() + 1).padStart(2, '0')
            let year = date.getFullYear()
            return `${year}-${month}-${day}`
        },
        updateData() {
            let widgetState = this.buildWidgetState()
            this.updateWidgetAttribute(widgetState)
        },
        updatePersons() {
            let widgetState = this.buildWidgetState()
            this.updateWidgetAttribute(widgetState)
        },
        buildWidgetState() {
            const personen = this.personen == '' ? 0 : this.personen
            return `bwr=ws|is|OrderItemOverview|and|o|is|true|and|v|is|[['volwassene',${personen}]]|and|d|is|${this.datum}|and|f|is|c|fis|activiteiten|cis|${this.activiteiten}|cand|arrangementen|cis|${this.arrangementen}|fand||and|ro|is|None`
        },
        updateWidgetAttribute(widgetState) {
            this.$refs.widgetButton.setAttribute('data-widget-state', widgetState)
        }
    }
}

window.obaia.Components.toggleText = () => {
    return {
        readMore: false,
        manualToggle: false,
        checkWindowSize() {
            this.readMore = window.innerWidth <= 768
        },
        init() {
            this.checkWindowSize()
        }
    }
}

window.obaia.Components.marquee = () => {
    let animationFrameId = null
    let shouldAnimate = false
    const throttleDelay = 33 // Throttle delay (approximately 33 = 30FPS, 16 = 60FPS)

    return {
        posX: 0,
        marqueeSpeed: 200, // Speed of the marquee (pixels per second)
        marqueeElement: null,
        marqueeWidth: 0,
        contentWidth: 0,
        clone: null,
        cloneCount: 0,
        logoItem: null,
        logoWidth: null,

        init() {
            this.marqueeElement = this.$refs.marquee
            this.marqueeWidth = this.marqueeElement.offsetWidth
            this.contentWidth = this.marqueeElement.scrollWidth

            this.logoItem = this.marqueeElement.querySelector('.js-logo-item')
            this.logoWidth = this.logoItem.offsetWidth
            // Wait for DOMContentLoaded event
            // Call handleResize initially to set up the marquee
            // document.addEventListener('DOMContentLoaded', () => console.log('DOC LOADED'))
            this.handleResize()
        },

        startAnimation() {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId)
            }

            const animate = () => {
                // this.moveMarquee()
                let isAnimating = false

                function throttle(callback, delay) {
                    let timeoutId = null

                    return function () {
                        if (!isAnimating) {
                            isAnimating = true
                            callback.apply(this, arguments)

                            timeoutId = setTimeout(() => {
                                isAnimating = false
                            }, delay)
                        }
                    }
                }

                // Wrap the moveMarquee function with the throttle function
                const throttledMoveMarquee = throttle(this.moveMarquee.bind(this), throttleDelay) // Adjust the delay as needed (e.g., 16ms for approximately 60 FPS)

                // Call the throttledMoveMarquee function instead of moveMarquee in the animation loop
                throttledMoveMarquee()

                animationFrameId = requestAnimationFrame(animate)
            }

            animationFrameId = requestAnimationFrame(animate)
        },

        stopAnimation() {
            cancelAnimationFrame(animationFrameId)
        },

        moveMarquee() {
            this.posX -= this.marqueeSpeed / 100

            if (this.posX < -this.contentWidth) {
                this.posX += this.contentWidth
            }

            this.marqueeElement.style.transform = `translate3d(${this.posX}px,0,0)`
        },

        handleResize() {
            const viewportWidth = window.innerWidth
            const shouldAnimateNew =
                this.contentWidth > this.marqueeWidth || this.contentWidth > viewportWidth

            if (shouldAnimateNew && !shouldAnimate) {
                // Start animation
                this.startAnimation()
                shouldAnimate = true

                // Remove existing clones
                this.removeClones()

                // Recreate clones based on the updated marquee width
                // const logoItem = this.marqueeElement.querySelector('.js-logo-item')
                // const logoWidth = logoItem.offsetWidth
                const clonesNeeded = Math.ceil(this.marqueeWidth / this.logoWidth) + 1

                this.cloneCount = clonesNeeded

                this.clone = this.marqueeElement.cloneNode(true)
                for (let i = 0; i < clonesNeeded; i++) {
                    if (this.marqueeElement.children[i]) {
                        this.clone.appendChild(this.marqueeElement.children[i].cloneNode(true))
                    }
                }

                this.marqueeElement.appendChild(this.clone)
            } else if (!shouldAnimateNew && shouldAnimate) {
                // Stop animation
                this.stopAnimation()
                shouldAnimate = false

                // Remove existing clones
                this.removeClones()
            }
        },

        removeClones() {
            this.marqueeElement.style.transform = 'translate3d(0px,0,0)'
            this.posX = 0
            if (this.clone) {
                this.marqueeElement.removeChild(this.clone)
                this.clone = null
                this.cloneCount = 0
            }
        }
    }
}
window.obaia.Components.miniParallax = (options) => {
    let lastScroll = 0;
    let ticking = false;

    return {
        top: '0px',
        threshold: options?.threshold ?? 4,
        direction: options?.direction ?? 'down',
        handleScroll() {
            lastScroll = window.scrollY;

            // Only request an animation frame if not already requested
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    this.update.call(this);
                });
                ticking = true;
            }
        },
        update() {
            // Update the value with the last known scroll position
            this.top = (this.direction === 'up' ? '-' : '') + Math.abs(Math.round(lastScroll / this.threshold)) + 'px';
            ticking = false;
        }
    };

}
// window.obaia.Components.listbox = function (e) {
//     return {
//         init() {
//             this.optionCount = this.$refs.listbox.children.length,
//                 this.$watch("activeIndex", (e => {
//                     this.open && (null !== this.activeIndex ? this.activeDescendant = this.$refs.listbox.children[this.activeIndex].id : this.activeDescendant = "")
//                 }
//                 ))
//         },
//         activeDescendant: null,
//         optionCount: null,
//         open: !1,
//         activeIndex: null,
//         selectedIndex: 0,
//         get active() {
//             return this.items[this.activeIndex]
//         },
//         get [e.modelName || "selected"]() {
//             return this.items[this.selectedIndex]
//         },
//         choose(e) {
//             this.selectedIndex = e,
//                 this.open = !1
//         },
//         onButtonClick() {
//             this.open || (this.activeIndex = this.selectedIndex,
//                 this.open = !0,
//                 this.$nextTick((() => {
//                     this.$refs.listbox.focus(),
//                         this.$refs.listbox.children[this.activeIndex].scrollIntoView({
//                             block: "nearest"
//                         })
//                 }
//                 )))
//         },
//         onOptionSelect() {
//             null !== this.activeIndex && (this.selectedIndex = this.activeIndex),
//                 this.open = !1,
//                 this.$refs.button.focus()
//         },
//         onEscape() {
//             this.open = !1,
//                 this.$refs.button.focus()
//         },
//         onArrowUp() {
//             this.activeIndex = this.activeIndex - 1 < 0 ? this.optionCount - 1 : this.activeIndex - 1,
//                 this.$refs.listbox.children[this.activeIndex].scrollIntoView({
//                     block: "nearest"
//                 })
//         },
//         onArrowDown() {
//             this.activeIndex = this.activeIndex + 1 > this.optionCount - 1 ? 0 : this.activeIndex + 1,
//                 this.$refs.listbox.children[this.activeIndex].scrollIntoView({
//                     block: "nearest"
//                 })
//         },
//         ...e
//     }
// }
// ,
window.obaia.Components.datepicker = function (options, urlBuilder) {
    return {
        value: options.defaultdate ? Date.now() : '',

        init() {
            // console.log(this.$el);
            // console.log(options.data);
            let picker = flatpickr(this.$el, {
                mode: options.mode,
                dateFormat: options.format ?? 'd-m-Y',
                plugins: (options.dateConnector ? [new rangePlugin({input: this.$el.closest('form').querySelector(options.dateConnector)})] : ''),
                inline: options.inline ?? false,
                defaultDate: this.value,
                minDate: "today",
                altInput: true,
                altFormat: options.altFormat ?? "d-m-Y",
                altInputClass: "user-readable input-date " + options.altColor ?? '',
                // locale: document.documentElement.getAttribute('lang').replace('-',''),
                locale: document.documentElement.getAttribute('lang').split('-')[0],
                enable: options.enable ? [
                    function (date) {
                        if (options.enable.includes(date.getDay())) {
                            return true
                        }
                    }
                ] : [function () {
                    return true
                }],
                onReady: (selectedDates, dateStr, instance) => {
                    if (options.dateConnector) {
                        const originalToField = this.$el.closest('form').querySelector(options.dateConnector)
                        const originalToFieldParent = originalToField.parentNode
                        const clonedToField = originalToField.cloneNode()
                        clonedToField.removeAttribute('data-fp-omit') // not sure about this though.
                        clonedToField.setAttribute('type', 'hidden')
                        clonedToField.removeAttribute('x-model')
                        originalToField.removeAttribute('id')
                        originalToField.removeAttribute('name')
                        originalToField.removeAttribute('required')
                        originalToFieldParent.appendChild(clonedToField)
                    }
                },
                onOpen: (date, dateString, instance) => {
                    if (options.enableCondition) {
                        let conditionBuilder = new Function("a", "b", "return a < b")

                        let backwardsCompatible = null
                        let dateArray = [
                            "02-01-2024",
                            "13-02-2024",
                            "20-02-2024",
                            "30-04-2024",
                            "01-05-2024",
                            "09-07-2024",
                            "16-07-2024",
                            "23-07-2024",
                            "30-07-2024",
                            "06-08-2024",
                            "13-08-2024",
                            "20-08-2024",
                            "27-08-2024",
                            "22-10-2024",
                            "29-10-2024",
                            "24-12-2024",
                            "31-12-2024",
                        ]
                        if (options.enable && options.data.urlBuilder !== undefined && options.data.urlBuilder.visitorClassifications[0].segments[0].visitors && conditionBuilder(options.data.urlBuilder.visitorClassifications[0].segments[0].visitors, options.enableCondition)) {
                             backwardsCompatible = 'OLD'
                        }

                        if (options.enable && options.data.personen && conditionBuilder(options.data.personen, options.enableCondition)) {
                             backwardsCompatible = 'NEW'
                            dateArray = dateArray.map(date => {
                                let [day, month, year] = date.split('-')
                                return `${year}-${month}-${day}`
                            })
                        }

                        if (backwardsCompatible) {
                            // if (options.enable && options.data.urlBuilder.visitorClassifications[0].segments[0].visitors && conditionBuilder(options.data.urlBuilder.visitorClassifications[0].segments[0].visitors, options.enableCondition)) {
                            instance.set("enable", [
                                function (date) {
                                    // TODO: als ze dicht willen op bepaalde dagen dan de volgende regels activeren
                                    // let renderedDates = date.getDate() + '-' + (date.getMonth() < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1 ) + '-' + date.getFullYear()
                                    // console.log((date.getMonth() < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1 ))
                                    // if (options.enable.includes(date.getDay()) && options.disabledDates.includes(renderedDates) === false) {
                                    if (options.enable.includes(date.getDay())) {
                                        return true
                                    }
                                    if (date.getDay() === 4 && [3, 4, 5, 6, 7, 8].includes(date.getMonth())) {
                                        return true
                                    }
                                },
                                ...dateArray
                                // "02-01-2024",
                                // "13-02-2024",
                                // "20-02-2024",
                                // "30-04-2024",
                                // "01-05-2024",
                                // "09-07-2024",
                                // "16-07-2024",
                                // "23-07-2024",
                                // "30-07-2024",
                                // "06-08-2024",
                                // "13-08-2024",
                                // "20-08-2024",
                                // "27-08-2024",
                                // "22-10-2024",
                                // "29-10-2024",
                                // "24-12-2024",
                                // "31-12-2024"
                            ]);
                        } else {
                            instance.set("enable", [function (date) {
                                return true
                            }]);
                        }
                    }
                },
                onChange: (date, dateString, instance) => {
                    let [from, to] = date

                    if (dateString.includes('t/m')) {
                        this.$el.value = dateString.split(' t/m ')[0]
                    }
                    if (dateString.includes('bis')) {
                        this.$el.value = dateString.split(' bis ')[0]
                    }
                    if (options.dateConnector) {
                        this.arrival = from ? instance.formatDate(from, 'D d M') : ''
                        this.departure = to ? instance.formatDate(to, 'D d M') : ''

                        // const originalToField = document.querySelector(options.dateConnector + ':not([type="hidden"])')
                        // const clonedToField = document.querySelector(options.dateConnector + '[type="hidden"]')
                        const originalToField = this.$el.closest('form').querySelector(options.dateConnector + ':not([type="hidden"])')
                        const clonedToField = this.$el.closest('form').querySelector(options.dateConnector + '[type="hidden"]')

                        // instance.input.value = to ? instance.formatDate(to, 'dateFormat') : '';
                        // clonedToField.value = from ? instance.formatDate(from, 'd-m-Y') : '';
                        // instance.input.value = to ? instance.formatDate(to, 'Y-m-d') : ''
                        originalToField.setAttribute('value', to ? instance.formatDate(to, 'd-m-Y') : '')
                        clonedToField.value = to ? instance.formatDate(to, 'Y-m-d') : ''
                    }

                    // Cubilis arrangmenten vereist 2 nachten minimaal
                    if (options.force === true && date.length === 1) {
                        const forceDays = options.forceDays ?? 2
                        // Get the selected start date
                        const startDate = from;
                        // Create a new date object for the end date
                        const endDate = new Date(startDate);
                        endDate.setDate(startDate.getDate() + forceDays); // Add 2 days
                        instance.setDate([startDate, endDate]);

                        const arrivalInput = document.getElementById('js-force-arrival')
                        const departureInput = document.getElementById('js-force-departure')
                        arrivalInput.value = from ? instance.formatDate(from, 'Y-m-d') : ''
                        departureInput.value = endDate ? instance.formatDate(endDate, 'Y-m-d') : ''
                    }
                }
            })
            this.$watch('value', () => picker.setDate(this.value))
        },
    }
}

window.obaia.Components.menu = function (e = {
    open: !1
}) {
    return {
        init() {
            this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]')),
                this.$watch("open", (() => {
                        this.open && (this.activeIndex = -1)
                    }
                ))
        },
        activeDescendant: null,
        activeIndex: null,
        items: null,
        open: e.open,
        focusButton() {
            this.$refs.button.focus()
        },
        onButtonClick() {
            this.open = !this.open,
            this.open && this.$nextTick((() => {
                    this.$refs["menu-items"].focus()
                }
            ))
        },
        onButtonEnter() {
            this.open = !this.open,
            this.open && (this.activeIndex = 0,
                this.activeDescendant = this.items[this.activeIndex].id,
                this.$nextTick((() => {
                        this.$refs["menu-items"].focus()
                    }
                )))
        },
        onArrowUp() {
            if (!this.open)
                return this.open = !0,
                    this.activeIndex = this.items.length - 1,
                    void (this.activeDescendant = this.items[this.activeIndex].id);
            0 !== this.activeIndex && (this.activeIndex = -1 === this.activeIndex ? this.items.length - 1 : this.activeIndex - 1,
                this.activeDescendant = this.items[this.activeIndex].id)
        },
        onArrowDown() {
            if (!this.open)
                return this.open = !0,
                    this.activeIndex = 0,
                    void (this.activeDescendant = this.items[this.activeIndex].id);
            this.activeIndex !== this.items.length - 1 && (this.activeIndex = this.activeIndex + 1,
                this.activeDescendant = this.items[this.activeIndex].id)
        },
        onClickAway(e) {
            if (this.open) {
                const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
                this.open = !1,
                e.target.closest(t) || this.focusButton()
            }
        }
    }
}

window.obaia.Components.popoverGroup = function () {
    return {
        __type: "popoverGroup",
        init() {
            let e = t => {
                    document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                        detail: this.$el
                    })) : window.removeEventListener("focus", e, !0)
                }
            ;
            window.addEventListener("focus", e, !0)
        }
    }
}

window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
    const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
    return {
        __type: "popover",
        open: e,
        init() {
            t && this.$watch("open", (e => {
                    e && this.$nextTick((() => {
                            !function (e) {
                                const t = Array.from(e.querySelectorAll(i));
                                !function e(i) {
                                    void 0 !== i && (i.focus({
                                        preventScroll: !0
                                    }),
                                    document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                }(t[0])
                            }(this.$refs.panel)
                        }
                    ))
                }
            ));
            let e = i => {
                    if (!document.body.contains(this.$el))
                        return void window.removeEventListener("focus", e, !0);
                    let n = t ? this.$refs.panel : this.$el;
                    if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                        let e = this.$el;
                        for (; e.parentNode;)
                            if (e = e.parentNode,
                            e.__x instanceof this.constructor) {
                                if ("popoverGroup" === e.__x.$data.__type)
                                    return;
                                if ("popover" === e.__x.$data.__type)
                                    break
                            }
                        this.open = !1
                    }
                }
            ;
            window.addEventListener("focus", e, !0)
        },
        onEscape() {
            this.open = !1,
            this.restoreEl && this.restoreEl.focus()
        },
        onClosePopoverGroup(e) {
            e.detail.contains(this.$el) && (this.open = !1)
        },
        toggle(e) {
            this.open = !this.open,
                this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
        }
    }
}

window.obaia.Components.radioGroup = function ({initialCheckedIndex: e = 0} = {}) {
    return {
        value: void 0,
        active: void 0,
        init() {
            let t = Array.from(this.$el.querySelectorAll("input"));
            this.value = t[e]?.value;
            for (let e of t)
                e.addEventListener("change", (() => {
                        // console.log(e.value);
                        this.active = e.value
                    }
                )),
                    e.addEventListener("focus", (() => {
                            this.active = e.value
                        }
                    ));
            window.addEventListener("focus", (() => {
                    // console.log("Focus change"),
                    t.includes(document.activeElement) || (console.log("HIT"), this.active = void 0
                    )
                }
            ), !0)
        }
    }
}
// ,
// window.obaia.Components.tabs = function () {
//     return {
//         selectedIndex: 0,
//         onTabClick(e) {
//             if (!this.$el.contains(e.detail))
//                 return;
//             let t = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
//                 , i = Array.from(this.$el.querySelectorAll('[x-data^="Components.tabPanel("]'))
//                 , n = t.indexOf(e.detail);
//             this.selectedIndex = n,
//                 window.dispatchEvent(new CustomEvent("tab-select", {
//                     detail: {
//                         tab: e.detail,
//                         panel: i[n]
//                     }
//                 }))
//         },
//         onTabKeydown(e) {
//             if (!this.$el.contains(e.detail.tab))
//                 return;
//             let t = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
//                 , i = t.indexOf(e.detail.tab);
//             "ArrowLeft" === e.detail.key ? this.onTabClick({
//                 detail: t[(i - 1 + t.length) % t.length]
//             }) : "ArrowRight" === e.detail.key ? this.onTabClick({
//                 detail: t[(i + 1) % t.length]
//             }) : "Home" === e.detail.key || "PageUp" === e.detail.key ? this.onTabClick({
//                 detail: t[0]
//             }) : "End" !== e.detail.key && "PageDown" !== e.detail.key || this.onTabClick({
//                 detail: t[t.length - 1]
//             })
//         }
//     }
// }
// ,
// window.obaia.Components.tab = function (e = 0) {
//     return {
//         selected: !1,
//         init() {
//             let t = Array.from(this.$el.closest('[x-data^="Components.tabs("]').querySelectorAll('[x-data^="Components.tab("]'));
//             this.selected = t.indexOf(this.$el) === e,
//                 this.$watch("selected", (e => {
//                     e && this.$el.focus()
//                 }
//                 ))
//         },
//         onClick() {
//             window.dispatchEvent(new CustomEvent("tab-click", {
//                 detail: this.$el
//             }))
//         },
//         onKeydown(e) {
//             ["ArrowLeft", "ArrowRight", "Home", "PageUp", "End", "PageDown"].includes(e.key) && e.preventDefault(),
//                 window.dispatchEvent(new CustomEvent("tab-keydown", {
//                     detail: {
//                         tab: this.$el,
//                         key: e.key
//                     }
//                 }))
//         },
//         onTabSelect(e) {
//             this.selected = e.detail.tab === this.$el
//         }
//     }
// }
// ,
// window.obaia.Components.tabPanel = function (e = 0) {
//     return {
//         selected: !1,
//         init() {
//             let t = Array.from(this.$el.closest('[x-data^="Components.tabs("]').querySelectorAll('[x-data^="Components.tabPanel("]'));
//             this.selected = t.indexOf(this.$el) === e
//         },
//         onTabSelect(e) {
//             this.selected = e.detail.panel === this.$el
//         }
//     }
// }