// core version + navigation, pagination modules:
// import Swiper, { Navigation, Pagination, Mousewheel, EffectCreative, Controller, EffectFade } from 'swiper'
import Swiper from 'swiper'
import { Navigation, Pagination, Mousewheel, EffectCreative, Controller, EffectFade, Parallax } from 'swiper/modules'

export function initSwiper() {

    const onzeBierenParallaxSwipers = document.querySelectorAll('.js-swiper-onze-bieren-parallax')
    for (let index = 0; index < onzeBierenParallaxSwipers.length; index++) {
        const element = onzeBierenParallaxSwipers[index]

        const onzeBierenParallaxSwiperBg = new Swiper(element.closest('section').querySelector('.js-swiper-onze-bieren-parallax-bg'), {
            modules: [Navigation,Controller, Parallax, Pagination],
            spaceBetween: 16,
            slidesPerView: 3,

            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 3,
                }
            }
        })


        const onzeBierenParallaxSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel, Parallax, Pagination,Controller],
            parallax: true,
            grabCursor: true,
            spaceBetween: 16,
            slidesPerView: 3,
            mousewheel: {
                forceToAxis: true,
            },
            pagination: {
                el: element.closest('section').querySelector('.js-swiper-pagination'),
                clickable: true,
            },
            // navigation: {
            //     prevEl: element.closest('section').querySelector('.js-swiper-button-prev'),
            //     nextEl: element.closest('section').querySelector('.js-swiper-button-next'),
            // },
            controller: {
                control: onzeBierenParallaxSwiperBg
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 3,
                }
            }
        })



    }

    const uitgelichteSwipers = document.querySelectorAll('.js-swiper-uitgelichte-winkels')
    for (let index = 0; index < uitgelichteSwipers.length; index++) {
        const element = uitgelichteSwipers[index]
        const uitgelichteSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel],
            grabCursor: true,
            spaceBetween: 16,
            slidesPerView: 3,
            mousewheel: {
                forceToAxis: true,
            },
            navigation: {
                prevEl: element.closest('section').querySelector('.js-swiper-button-prev'),
                nextEl: element.closest('section').querySelector('.js-swiper-button-next'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 3,
                }
            }
        })
    }

    const contactsSwipers = document.querySelectorAll('.js-swiper-contacts')
    for (let index = 0; index < contactsSwipers.length; index++) {
        const element = contactsSwipers[index]
        const contactSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel],
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: 16,
            mousewheel: {
                forceToAxis: true,
            },
            navigation: {
                prevEl: element.closest('section').querySelector('.js-swiper-button-prev'),
                nextEl: element.closest('section').querySelector('.js-swiper-button-next'),
            },
        })
    }

    const counterSwipers = document.querySelectorAll('.js-swiper-counter')
    for (let index = 0; index < counterSwipers.length; index++) {
        const element = counterSwipers[index]
        const indexNumber = element.closest('section').querySelector('.js-swiper-index')
        const indexTotalNumber = element.closest('section').querySelector('.js-swiper-total-slides')

        const counterSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel, EffectCreative],
            effect: 'creative',
            creativeEffect: {
                // progressMultiplier: 2,
                limitProgress: 3,
                prev: {
                    shadow: false,
                    origin: "center center",
                    translate: ["-100%", "0%", -100],
                    rotate: [0, 0, 0],
                    scale: 1
                },
                next: {
                    shadow: false,
                    origin: "center center",
                    translate: ["100%", "0%", 100],
                    rotate: [0, 0, 0],
                    scale: 0.2
                },
            },
            grabCursor: true,
            spaceBetween: 16,
            slidesPerView: 1,
            mousewheel: {
                forceToAxis: true,
                releaseOnEdges: true
            },
            navigation: {
                prevEl: element.closest('section').querySelector('.js-swiper-button-prev'),
                nextEl: element.closest('section').querySelector('.js-swiper-button-next'),
            },
            on: {
                beforeInit: function (sw) {
                    indexNumber.innerHTML = (sw.activeIndex + 1)
                    let numOfSlides = this.wrapperEl.querySelectorAll(".swiper-slide").length
                    // indexTotalNumber.innerHTML = '/' + sw.slides.length
                    indexTotalNumber.innerHTML = '/' + numOfSlides
                },
                activeIndexChange: function (sw) {
                    // indexNumber.innerHTML = (sw.activeIndex + 1) + '/' + sw.slides.length + 'Offers'
                    indexNumber.innerHTML = (sw.activeIndex + 1)
                }
            }
        })
    }

    const textImageSwipers = document.querySelectorAll('.js-swiper-text-image')
    for (let index = 0; index < textImageSwipers.length; index++) {
        const element = textImageSwipers[index]
        const textImageSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel, EffectCreative],
            effect: 'creative',
            creativeEffect: {
                progressMultiplier: 0.8,
                limitProgress: 4,
                prev: {
                    shadow: false,
                    origin: "center center",
                    translate: ["-140%", "10%", -100],
                    rotate: [0, 20, 0],
                    scale: 1
                },
                next: {
                    shadow: false,
                    origin: "center center",
                    translate: ["140%", "10%", -100],
                    rotate: [0, -20, 0],
                    scale: 1
                },
            },
            slidesPerView: 'auto',
            // slidesPerView: 2,
            centeredSlides: false,
            spaceBetween: 16,
            grabCursor: true,
            mousewheel: {
                forceToAxis: true,
            },
            navigation: {
                prevEl: element.querySelector('.js-swiper-button-prev'),
                nextEl: element.querySelector('.js-swiper-button-next'),
            },
        })
        textImageSwiper.slideTo(0)
    }

    const onzeBierenSwipers = document.querySelectorAll('.js-swiper-bieren')
    for (let index = 0; index < onzeBierenSwipers.length; index++) {
        const element = onzeBierenSwipers[index]
        const indexNumber = element.closest('section').querySelector('.js-swiper-index')
        const indexTotalNumber = element.closest('section').querySelector('.js-swiper-total-slides')

        const elementControllerElement = element.closest('section').querySelector('.js-swiper-bieren-text')
        const bierenTextSwiper = new Swiper(elementControllerElement, {
            modules: [Navigation, Mousewheel, EffectFade, Controller],
            effect: 'fade',
            autoHeight: true,
            fadeEffect: {
                crossFade: true
            },
        })
        const bierenSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel, EffectCreative, Controller],
            effect: 'creative',
            grabCursor: true,
            spaceBetween: 16,
            slidesPerView: 1,
            mousewheel: {
                forceToAxis: true,
                // releaseOnEdges: true
            },
            navigation: {
                prevEl: element.closest('section').querySelector('.js-swiper-button-prev'),
                nextEl: element.closest('section').querySelector('.js-swiper-button-next'),
            },
            on: {
                beforeInit: function (sw) {
                    indexNumber.innerHTML = (sw.activeIndex + 1)
                    let numOfSlides = this.wrapperEl.querySelectorAll(".swiper-slide").length
                    // indexTotalNumber.innerHTML = '/' + sw.slides.length
                    indexTotalNumber.innerHTML = '/' + numOfSlides
                },
                activeIndexChange: function (sw) {
                    // indexNumber.innerHTML = (sw.activeIndex + 1) + '/' + sw.slides.length + 'Offers'
                    indexNumber.innerHTML = (sw.activeIndex + 1)
                },
                slideChange: function(sw) {
                    setTimeout(function () {
                        sw.params.mousewheel.releaseOnEdges = false;
                    }, 750);
                },
                reachBeginning: function(sw) {
                    setTimeout(function () {
                        sw.params.mousewheel.releaseOnEdges = true;
                    }, 1000);
                },
                reachEnd: function(sw) {
                    setTimeout(function () {
                        sw.params.mousewheel.releaseOnEdges = true;
                    }, 1000);
                }
            },
            breakpoints: {
                0: {
                    creativeEffect: {
                        // progressMultiplier: 2,
                        limitProgress: 3,
                        prev: {
                            shadow: false,
                            origin: "center center",
                            translate: ["-100%", "0%", -100],
                            rotate: [0, 0, 0],
                            scale: 1
                        },
                        next: {
                            shadow: false,
                            origin: "center center",
                            translate: ["100%", "0%", -100],
                            rotate: [0, 0, 0],
                            scale: 0.4
                        },
                    },
                },
                768: {
                    direction: 'vertical',
                    creativeEffect: {
                        limitProgress: 3,
                        prev: {
                            shadow: false,
                            origin: "center center",
                            translate: ["-20%", "-100%", -100],
                            rotate: [0, 0, -10],
                            scale: 1
                        },
                        next: {
                            shadow: false,
                            origin: "center center",
                            translate: ["20%", "100%", -100],
                            rotate: [0, 0, 10],
                            scale: 0.9
                        },
                    },
                },
            }
        })
        bierenTextSwiper.controller.control = bierenSwiper
        bierenSwiper.controller.control = bierenTextSwiper

    }

    const inleidingSwipers = document.querySelectorAll('.js-swiper-inleiding')
    for (let index = 0; index < inleidingSwipers.length; index++) {
        const element = inleidingSwipers[index]
        const inleidingSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel],
            grabCursor: true,
            spaceBetween: 16,
            slidesPerView: 1,
            mousewheel: {
                forceToAxis: true,
            },
            navigation: {
                prevEl: element.closest('section').querySelector('.js-swiper-button-prev'),
                nextEl: element.closest('section').querySelector('.js-swiper-button-next'),
            },
        })
    }

    const simpleSwipers = document.querySelectorAll('.js-swiper-simple')
    for (let index = 0; index < simpleSwipers.length; index++) {
        const element = simpleSwipers[index]
        const simpleSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel],
            grabCursor: true,
            spaceBetween: 16,
            mousewheel: {
                forceToAxis: true,
            },
            navigation: {
                prevEl: element.querySelector('.js-swiper-button-prev'),
                nextEl: element.querySelector('.js-swiper-button-next'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                }
            }
        })
    }

    const simpleCardSwipers = document.querySelectorAll('.js-swiper-simple-cards')
    for (let index = 0; index < simpleCardSwipers.length; index++) {
        const element = simpleCardSwipers[index]
        const simpleCardSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel],
            grabCursor: true,
            spaceBetween: 16,
            mousewheel: {
                forceToAxis: true,
            },
            navigation: {
                prevEl: element.querySelector('.js-swiper-button-prev'),
                nextEl: element.querySelector('.js-swiper-button-next'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
            }
        })
    }

    const cardSwipers = document.querySelectorAll('.js-swiper-card')
    for (let index = 0; index < cardSwipers.length; index++) {
        const element = cardSwipers[index]
        const cardSwiper = new Swiper(element, {
            modules: [Navigation, Mousewheel, EffectCreative],
            effect: 'creative',
            creativeEffect: {
                // progressMultiplier: 2,
                limitProgress: 2,
                prev: {
                    shadow: false,
                    origin: "center center",
                    translate: ["-20%", 0, -100],
                    rotate: [0, 0, -10],
                    scale: 1
                },
                next: {
                    shadow: false,
                    origin: "center center",
                    translate: ["20%", 0, -100],
                    rotate: [0, 0, 10],
                    scale: 1.1
                },
            },
            slidesPerView: 'auto',
            centeredSlides: true,
            grabCursor: true,
            mousewheel: {
                forceToAxis: true,
            },
            navigation: {
                prevEl: element.querySelector('.js-swiper-button-prev'),
                nextEl: element.querySelector('.js-swiper-button-next'),
            },
        })
    }

    const tabsliderSwiper = document.querySelectorAll('.js-swiper-tabslider-images')
    for (let index = 0; index < tabsliderSwiper.length; index++) {
        const tabsliderImages = tabsliderSwiper[index]
        const tabslider = tabsliderImages.closest('section').querySelector('.js-swiper-tabslider')
        const colormode = tabslider.getAttribute('data-colormode')
        const swiperImages = new Swiper(tabsliderImages, {
            modules: [Mousewheel, Controller],
            slidesPerView: 1,
            spaceBetween: 100,
            mousewheel: {
                forceToAxis: true,
            },
        })
        const swiperText = new Swiper(tabslider, {
            modules: [Navigation, Pagination, Mousewheel, Controller],
            slidesPerView: 1,
            mousewheel: {
                forceToAxis: true,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    let names = [];
                    const slides = tabslider.querySelectorAll('.swiper-slide')
                    for (let index = 0; index < slides.length; index++) {
                        const slide = slides[index];
                        names.push(slide.getAttribute('data-name'));
                    }
                    return '<span class="text-xs font-black uppercase tracking-200 cursor-pointer ' + colormode + ' ' + className + '">' + names[index] + '</span>'
                },
            },
            navigation: {
                prevEl: tabslider.closest('section').querySelector('.js-swiper-button-prev'),
                nextEl: tabslider.closest('section').querySelector('.js-swiper-button-next'),
            },
        })
        swiperText.controller.control = swiperImages
        swiperImages.controller.control = swiperText
    }
}
initSwiper()