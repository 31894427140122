/**
 * This file kicks off Webpack's tasks to minify and compile CSS and JS.
 */
const flame = String.fromCodePoint(0x1f525)

// CSS imports
import './assets/scss/main.scss'

// JS imports
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import mask from '@alpinejs/mask'
import './assets/js/_obaia-window-components'
import './assets/js/_obaia-window-helpers'
import tippy, { followCursor } from 'tippy.js';
 
document.addEventListener('alpine:init', () => {
    // Magic: $tooltip
    Alpine.magic('tooltip', el => message => {
        let instance = tippy(el, { content: message, trigger: 'click', theme: 'othmar', hideOnClick: "toggle" })
        instance.show()
        setTimeout(() => {
            instance.hide()
            setTimeout(() => instance.destroy(), 150)
        }, 2000)
    })

    // Directive: x-tooltip
    Alpine.directive('tooltip', (el, { expression }) => {
        tippy(el, {
            content: expression,
            theme: 'othmar',
            followCursor: true,
            plugins: [followCursor],
            allowHTML: true,
        })
    })
})

Alpine.plugin(mask)
Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.start()



// Components
import './assets/js/_validator-pristine'
// import './assets/js/_header'
// import './assets/js/_micromodal'
import './assets/js/_swiper'

// * END OF FILE
console.log(`%c
            
            `+flame+` Made with a keyboard by `+flame+`

 ________      ________      ________      ___      ________     
|\\   __  \\    |\\   __  \\    |\\   __  \\    |\\  \\    |\\   __  \\    
\\ \\  \\|\\  \\   \\ \\  \\|\\ /_   \\ \\  \\|\\  \\   \\ \\  \\   \\ \\  \\|\\  \\   
 \\ \\  \\\\\\  \\   \\ \\   __  \\   \\ \\   __  \\   \\ \\  \\   \\ \\   __  \\  
  \\ \\  \\\\\\  \\   \\ \\  \\|\\  \\   \\ \\  \\ \\  \\   \\ \\  \\   \\ \\  \\ \\  \\ 
   \\ \\_______\\   \\ \\_______\\   \\ \\__\\ \\__\\   \\ \\__\\   \\ \\__\\ \\__\\
    \\|_______|    \\|_______|    \\|__|\\|__|    \\|__|    \\|__|\\|__|


                            WEBDEVELOPMENT
                            https://obaia.nl

`, `font-family: monospace;`)